import './bootstrap';

const csrfToken = $('meta[name="csrf-token"]').attr('content');
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': csrfToken
    }
});


$(document).ready(function () {

    let containerWidth = $('header .container').width()

    // $('.hero-main svg').width(containerWidth);

    $('.contacts input, .contacts textarea, .feedback input').on('input', function () {
        $(this).removeClass('is-invalid');
    });


    $('#email-form').on('submit', function (event) {
        event.preventDefault();

        var inputPhone = $(this).find('[name=phone]').val();
        var inputName = $(this).find('[name=name]').val();

        if(inputPhone !== '' && inputName !== '') {
            $(this).find('[type=submit]').prop('disabled', true);
        }

        const formData = new FormData(this);
        let lang = $('html').data('lang');
        formData.append('lang', lang);

        $('.contacts .error, .feedback .error').hide();
        $('.contacts .error .form-error-text, .feedback .error .form-error-text').empty();
        $('.contacts input, .contacts textarea, .feedback input').removeClass('is-invalid');

        $.ajax({
            url:  '/api/sendmail',
            method: 'POST',
            data: formData,
            processData: false,
            contentType: false,
            success: function (response) {
                location.href = lang == 'ua' ? '/contacts/success' : '/'+lang+'/contacts/success';

            },

            error: function (xhr, status, errorThrown) {
                $('.contacts .error, .feedback .error').show();
                $('.contacts .error .form-error-text, .feedback .error .form-error-text').empty();
                $('.contacts input, .contacts textarea, .feedback input').removeClass('is-invalid');

                if (xhr.responseJSON && xhr.responseJSON.errors) {
                    var errorList = $('<ul>');

                    console.log(xhr.responseJSON.errors)

                    $.each(xhr.responseJSON.errors, function (field, errorMessages) {
                        if (Array.isArray(errorMessages)) {
                            $.each(errorMessages, function (index, errorMessage) {
                                errorList.append($('<li>').text(errorMessage));
                            });

                            $('[name="' + field + '"]').addClass('is-invalid');
                        }
                    });

                    if (errorList.children().length > 0) {
                        console.log(errorList)
                        $('.feedback .error .form-error-text').append(errorList);
                    }
                } else {
                    console.error('Unexpected Error:', xhr.statusText);
                }
            }

        });



    });



    $('.brief-form').on('submit', function (event) {
        event.preventDefault();

        var inputPhone = $(this).find('[name=phone]').val();
        var inputName = $(this).find('[name=name]').val();
        var inputEmail = $(this).find('[name=email]').val();

        if(inputPhone !== '' && inputEmail !== '' && inputName !== '') {
            $(this).find('[type=submit]').prop('disabled', true);
        }


        let formData = new FormData(this);
        let lang = $(document).find('html').data('lang')

        $.ajax({
            url: '/api/'+ lang +'/send-brief',
            method: 'post',
            data: formData,
            processData: false,
            contentType: false,
            success: function (response) {

                $('.brief-form').remove();
                $('.brief-answer.error').addClass('d-none');
                $('.brief-answer.success').removeClass('d-none');
                $('.brief-form .error .form-error-text').empty();

            },
            error: function (error) {
                $('.brief-answer.error').removeClass('d-none');
                $('.brief-answer.success').addClass('d-none');
                $('.brief-form .error .form-error-text').empty();

                if (error.responseJSON.errors) {
                    Object.values(error.responseJSON.errors).forEach(function (errorText) {
                        $('.brief-answer.error .error .form-error-text').append($('<p>').text(errorText));
                    });
                } else {
                    console.error(error);
                }
            }
        });
    });


    $('.btn-chat__btn-main').click(function() {
        let btn_chat__links = $('.btn-chat__links');
        let btn_chat__btn_main = $('.btn-chat__btn-main');

        $(btn_chat__btn_main).toggleClass('active');
        $(btn_chat__links).toggleClass('active');

        if (btn_chat__links.hasClass('active')) {
            btn_chat__links.css('visibility', 'visible');
        } else {
            btn_chat__links.css('visibility', 'hidden');
        }
    });



    $('.form-group .checkmark').click(function() {
        $(this).parent().find('label').click();
    });

});


$(document).ready(function () {
    $('.multi-inputs').on('input', '.multi-input input', function () {
        check_multiinputs($(this));
    });

    $('.multi-inputs').on('click', '.multi-input-close', function () {
        delete_multi_input($(this));
    });

});

$(document).ready(function () {
    $('.form-group').on('input', '.other', function () {
        let input = $(this);
        let radioButtons = $('input[type="radio"]');

        if (input.val().trim() !== '') {
            radioButtons.prop('checked', false);
        }
    });

    $('.btn-navbar-mobile').click(function(e) {
        e.preventDefault()

        $(this).find('svg').toggleClass('show');

        $('.mobile-menu-wrapper').toggleClass('show');

        setTimeout(function() {
            $('.mobile-menu-wrapper__navbar').toggleClass('show');
        }, 200)
    })

    $('#phone').inputmask({
        mask: '+9{1,4}999999999',
        placeholder: '',
    });

    $("#phone").focus(function(){
        if ($(this).val() === "") {
            $(this).val("+380");
        }
    });

    $("#phone").blur(function(){
        if ($(this).val() === "+380") {
            $(this).val("");
        }
    });

});


function check_multiinputs(elm) {
    let parent = elm.closest('.multi-inputs');
    let inputs = parent.find('.multi-input input');
    let last_multi_input = parent.find('.multi-input').last();

    let is_add = false;
    if (inputs.last().val() !== '') {
        is_add = true;
    }

    if (is_add) {
        let new_multi_input = last_multi_input.clone();
        new_multi_input.find('input').val('');
        parent.append(new_multi_input);
        updateCloseIcons(parent);
    }

    updateCloseIcons(parent);
}

function delete_multi_input(elm) {
    let parent = elm.closest('.multi-input');
    let multiInputs = parent.closest('.multi-inputs').find('.multi-input');

    if (multiInputs.length > 1) {
        parent.remove();
    }

    updateCloseIcons(multiInputs);
}

function updateCloseIcons(elm) {
    let parent = elm.closest('.multi-inputs');
    let multiInputs = parent.find('.multi-input');
    let closeIcons = multiInputs.find('.multi-input-close');

    if (multiInputs.length === 1) {
        closeIcons.removeClass('not-empty');
    } else {
        closeIcons.addClass('not-empty');
    }
}

